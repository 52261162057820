import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Navigation2 from "../components/navigation2"
import HomeContacto from "../components/home-contacto"
import ius from "../images/iuspublik.jpg"

export const query = graphql`
  {
    isopublik: allDatoCmsIsopublik {
      nodes {
        pais
        bufete
        contacto
        slug
      }
    }
  }
`

const IsopublikPage = ({ data }) => (
  <Layout pageTitle="Isopublik">
    <div className="w-full lg:w-10/12 mx-auto">
      <Navigation2 />

      <div className="w-full lg:flex mx-auto">
        <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
          <img
            src={ius}
            alt="IUSPUBLIK"
            className=" w-full object-cover lg:p-5 lg:pt-0"
          />
        </div>
        <div className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0">
          <h1 className="text-3xl mb-10">IUSPUBLIK</h1>
          <div className="w-full">
            {data.isopublik.nodes.map(bufete => (
              <div className="contact w-full lg:w-1/2 mt-10" key={bufete.slug}>
                <h2 className="text-lg">{bufete.pais}</h2>
                <h3 className="mb-3">{bufete.bufete}</h3>
                <div
                  className="bufete-contacto"
                  dangerouslySetInnerHTML={{
                    __html: bufete.contacto,
                  }}
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>

    <HomeContacto />
  </Layout>
)

export default IsopublikPage
